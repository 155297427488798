import { Controller } from "@hotwired/stimulus"
import { escapeHTML } from "helpers/dom"
import I18n from "helpers/i18n"

export default class extends Controller {
  static targets = ["text", "checkbox", "selectedItems", "selectedCount", "formValue", "select"]
  static values = {
    select: String,
    selectAll: String,
    selectAllChecked: Boolean,
  }

  initialize() {
    if (this.selectAllCheckedValue === true) {
      this.checkAll()
    } else {
      this.updateSelectorInfo()
    }
  }

  allCheckboxesChecked() {
    return this.checkboxTargets
      .filter((checkbox) => checkbox.value !== "select_all_option")
      .every((checkbox) => checkbox.checked)
  }

  onCheckboxClick() {
    if (this.getSelectAllCheckbox()) {
      if (this.getSelectAllCheckbox().checked) {
        this.getSelectAllCheckbox().checked = false
      }
      if (this.allCheckboxesChecked()) {
        this.getSelectAllCheckbox().checked = true
        this.selectAllCheckedValue = true
      }
    }
    this.updateSelectorInfo()
  }

  selectWithEnter(event) {
    event.preventDefault() // Prevent form submission
    event.target.click() // Trigger checkbox click
  }

  getSelectAllCheckbox() {
    return this.checkboxTargets.find((checkbox) => checkbox.value === "select_all_option")
  }

  toggleAll() {
    if (this.getSelectAllCheckbox() && this.getSelectAllCheckbox().checked) {
      this.selectAllCheckedValue = true
      this.checkAll()
    } else {
      this.selectAllCheckedValue = false
      this.uncheckAll()
    }
  }

  checkAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = "checked"
    })
    this.updateSelectorInfo()
    this.textTarget.innerText = this.selectAllValue
  }

  uncheckAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.updateSelectorInfo()
  }

  // If you press the enter key, and a single item is filtered to, select it.
  selectSingleItemIfFiltered(event) {
    const visibleCheckboxes = this.checkboxTargets.filter((box) => box.parentElement.style.display !== "none")
    if (visibleCheckboxes.length === 1) {
      visibleCheckboxes[0].click() // Trigger checkbox click

      event.target.value = "" // Reset filter input
      event.target.dispatchEvent(new Event("change"))
    }
  }

  // Helpers

  updateSelectorInfo() {
    this.selectedItemsTarget.innerHTML = ""
    const checkedItemsList = this.getChecked()
    const checkedObjects = this.getCheckedObjects()

    this.showSelectedPills(checkedObjects)
    this.showSelectedText(checkedItemsList)
  }

  getChecked() {
    return this.checkboxTargets
      .filter((box) => box.checked)
      .map((item) => item.dataset.name)
      .filter((item) => item !== this.selectAllValue)
  }

  getCheckedObjects() {
    return this.checkboxTargets.filter((box) => box.checked).filter((item) => item.dataset.name !== this.selectAllValue)
  }

  getSelectedMobile() {
    // .selectedOptions returns a HTML collection - transform into an array
    const selected = Array.from(this.selectTarget.selectedOptions).map(({ value }) => value)
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
    selected.forEach((id) => {
      const itemToCheck = this.checkboxTargets.find((checkbox) => checkbox.value === id)
      itemToCheck.checked = "checked"
    })
  }

  showSelectedPills(checkedItemsList, expand = false) {
    if (checkedItemsList.length) {
      this.selectedItemsTarget.classList.remove("hidden")
      const isLongList = checkedItemsList.length > 6 && !expand
      const list = isLongList ? checkedItemsList.slice(0, 6) : checkedItemsList
      const filteredList = list.filter((item) => item !== this.selectAllValue)
      filteredList.forEach((item) => {
        // fine because buildPill escapes
        // eslint-disable-next-line no-unsanitized/method
        this.selectedItemsTarget.insertAdjacentHTML("beforeend", this.buildPill(item))
      })
      if (isLongList) {
        // fine because buildPill escapes
        // eslint-disable-next-line no-unsanitized/method
        this.selectedItemsTarget.insertAdjacentHTML("beforeend", this.buildPill("..."))
      }
    }
  }

  buildPill(item) {
    const css =
      "group bg-gray-75 text-sm rounded-full px-2 my-1 text-gray-900 flex dark:bg-darkmode-blue-200 dark:text-darkmode-gray-900 hover:bg-gray-100 dark:hover:bg-darkmode-blue-500 cursor-pointer"
    const crossIconCss = "h-3 w-3 ml-0.5 self-center visible opacity-75 pointer-events-none"

    if (item === "...") {
      return `<div class="${css}" data-action="click->design-components--multiselect-with-search#seeAllPills">${escapeHTML(
        item
      )}</div>`
    } else {
      return `
      <div id="${item.id}" class="${css}" data-action="click->design-components--multiselect-with-search#pillClicked">
        ${escapeHTML(item.dataset.name)}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke-width="1.5" stroke="currentColor" class="${crossIconCss}">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" style= "fill-rule:evenodd;clip-rule:evenodd" />
        </svg>
      </div>
      `
    }
  }

  showSelectedText(checkedItemsList) {
    this.textTarget.innerText = checkedItemsList.join(", ")
    if (this.allCheckboxesChecked() && this.selectAllValue) {
      this.textTarget.innerText = this.selectAllValue
    }
    if (checkedItemsList.length > 0) {
      this.selectedCountTarget.classList.remove("hidden")
      this.selectedCountTarget.innerText = I18n.t("js.generic_components.multiselect.items_selected", {
        count: checkedItemsList.length,
      })
    } else {
      this.selectedCountTarget.classList.add("hidden")
      this.textTarget.innerText = this.selectValue
    }
  }

  pillClicked(event) {
    const pillId = event.target.id
    const checkbox = this.checkboxTargets.find((box) => box.id === pillId)
    checkbox.click()
  }

  seeAllPills(event) {
    this.selectedItemsTarget.innerHTML = ""
    const checkedItemsList = this.getCheckedObjects()
    this.showSelectedPills(checkedItemsList, true)
  }
}
